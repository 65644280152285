<template>
  <div>
    <customer-view
      v-if="viewMode"
      :items="items"
      :personal-info="tabbedPersonalInfo"
    >
      <template #title> Profile </template>

      <template #control>
        <v-btn icon @click="changeMode">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <template #file-control="{ filekey }">
        <v-btn @click="getDocument(filekey)">
          <v-icon color="blue darken-2"> mdi-download </v-icon>
        </v-btn>
        <v-btn
          :loading="deleteDocumentLoadingKey === filekey"
          @click="deleteDocument(filekey)"
        >
          <v-icon color="orange darken-2"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </customer-view>

    <div v-else>
      <v-stepper v-model="selectedStep">
        <v-stepper-header>
          <template v-for="(header, idx) in stepHeaders">
            <v-stepper-step
              :complete="selectedStep > idx + 1"
              :step="idx + 1"
              :key="`header--${idx}`"
              >{{ header }}</v-stepper-step
            >
            <v-divider
              v-if="idx < stepHeaders.length"
              :key="`divider--${idx}`"
            />
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card color="white lighten-1">
              <v-form ref="form1" v-model="formStepValidities[1]">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.given_name"
                        :rules="nameRules"
                        :counter="25"
                        label="First name"
                        required
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.family_name"
                        :rules="nameRules"
                        :counter="25"
                        label="Last name"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="isDateOfBirthMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="personalInfoModel.birthdate"
                            label="Date of Birth"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="personalInfoModel.birthdate"
                          @input="isDateOfBirthMenu = false"
                          :max="new Date().toISOString().slice(0, 10)"
                          min="1950-01-01"
                          required
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="d-flex" cols="12" md="4">
                      <v-select
                        v-model="personalInfoModel.gender"
                        :rules="genderRules"
                        :items="genders"
                        label="Gender"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="4">
                      <v-select
                        v-model="personalInfoModel.maritalStatus"
                        :items="maritalStatuses"
                        label="Marital Status"
                        :rules="maritalRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.address.address1"
                        label="Address 1"
                        :rules="addressRules"
                        :counter="40"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.address.address2"
                        label="Address 2"
                        :rules="addressRules"
                        :counter="40"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.address.city"
                        label="City/Town"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="personalInfoModel.address.country"
                        label="Country"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card color="white lighten-1">
              <v-form ref="form2" v-model="formStepValidities[2]">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.phone_number"
                        disabled
                        :rules="phoneRules"
                        label="Mobile Phone"
                        required
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.home_number"
                        :rules="phoneRules"
                        label="Home Phone"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.email"
                        disabled
                        :rules="emailRules"
                        label="E-mail"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="personalInfoModel.social_media[0].name"
                        :items="socialMedia"
                        label="Social Media"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.social_media[0].handle"
                        label="Social Media Username"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.next_kin.name"
                        label="Next of kin name"
                        :rules="nameRules"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.next_kin.number"
                        label="Next of kin number"
                        :rules="phoneRules"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="4">
                      <v-select
                        v-model="personalInfoModel.next_kin.relation"
                        :items="relations"
                        label="Next of kin relation"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card color="white lighten-1">
              <v-form ref="form3" v-model="formStepValidities[3]">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.employment.name"
                        label="Name of Employer"
                        required
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="isEmployFromDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="personalInfoModel.employment.fromDate"
                            label="From Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="personalInfoModel.employment.fromDate"
                          @input="isEmployFromDate = false"
                          :max="new Date().toISOString().slice(0, 10)"
                          min="1950-01-01"
                          required
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="isEmployToDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="personalInfoModel.employment.toDate"
                            label="To Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="personalInfoModel.employment.toDate"
                          @input="isEmployToDate = false"
                          :max="new Date().toISOString().slice(0, 10)"
                          min="1950-01-01"
                          required
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.employment.net_salary"
                        label="Base Net Monthly"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.employment.job_title"
                        label="Job Title"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="4">
                      <v-select
                        v-model="personalInfoModel.employment.pay_cycle"
                        :items="payment_cycles"
                        label="Pay Cycle"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-radio-group
                        v-model="personalInfoModel.employment.private_sector"
                        row
                      >
                        <v-radio label="Public Sector" :value="false" />
                        <v-radio label="Private Sector" :value="true" />
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.employment.hourly_rate"
                        label="Paid By Hour Rate"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.employment.address.address1"
                        label="Employer's Address 1"
                        :rules="addressRules"
                        :counter="40"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.employment.address.address2"
                        label="Employer's Address 2"
                        :rules="addressRules"
                        :counter="40"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="personalInfoModel.employment.address.city"
                        label="Employer's City/Town"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="personalInfoModel.employment.address.country"
                        label="Employer's Country"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card color="white lighten-1">
              <v-form ref="form4" v-model="formStepValidities[4]">
                <v-container>
                  <v-row>
                    <v-col class="d-flex" cols="12" md="4">
                      <v-select
                        v-model="personalInfoModel.number_of_dependents"
                        :items="dependants"
                        label="Number of Dependants"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-radio-group
                        v-model="personalInfoModel.outstanding_loan.isAvailable"
                        label="Any Outstanding Loans?"
                        row
                      >
                        <v-radio label="Yes" :value="true" />
                        <v-radio label="No" :value="false" />
                      </v-radio-group>
                    </v-col>
                    <v-col
                      v-show="personalInfoModel.outstanding_loan.isAvailable"
                      cols="12"
                      md="6"
                    >
                      <v-radio-group
                        v-model="
                          personalInfoModel.outstanding_loan.reflected_on_slip
                        "
                        label="Is it reflected on pay slip?"
                        row
                      >
                        <v-radio label="Yes" :value="true" />
                        <v-radio label="No" :value="false" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row
                    v-show="
                      personalInfoModel.outstanding_loan.isAvailable &&
                      personalInfoModel.outstanding_loan.reflected_on_slip
                    "
                  >
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.outstanding_loan.institution"
                        label="Which Institute?"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.outstanding_loan.principal"
                        label="Principal"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="personalInfoModel.outstanding_loan.amount"
                        label="Amount/Frequency"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="5">
            <v-card color="white lighten-1">
              <v-form ref="form5" v-model="formStepValidities[5]">
                <v-container>
                  <v-row>
                    <v-col class="d-flex" cols="12" md="6">
                      <v-select
                        v-model="personalInfoModel.bank_accounts[0].bank_name"
                        :items="banks"
                        label="Salaried Bank"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.bank_accounts[0].branch_name"
                        label="Branch"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.bank_accounts[0].account_no"
                        label="Account Number"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6">
                      <v-select
                        v-model="personalInfoModel.bank_accounts[0].type"
                        :items="accountTypes"
                        label="Account Type"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-radio-group
                        v-model="personalInfoModel.bank_accounts[0].deposit"
                        label="Deposit to salary account?"
                        @change="chkDepositBankAccount"
                        row
                      >
                        <v-radio label="Yes" :value="true" />
                        <v-radio label="No" :value="false" />
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row v-if="personalInfoModel.bank_accounts.length > 1">
                    <v-col class="d-flex" cols="12" md="6">
                      <v-select
                        v-model="personalInfoModel.bank_accounts[1].bank_name"
                        :items="banks"
                        label="Bank"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.bank_accounts[1].branch_name"
                        label="Branch"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="personalInfoModel.bank_accounts.length > 1">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="personalInfoModel.bank_accounts[1].account_no"
                        label="Account Number"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6">
                      <v-select
                        v-model="personalInfoModel.bank_accounts[1].type"
                        :items="accountTypes"
                        label="Account Type"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="6">
            <v-card color="white lighten-1">
              <v-form ref="form6" v-model="formStepValidities[6]">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        Kindly Upload the Following Documents. Only Images and
                        PDF Documents Allowed
                      </p>
                    </v-col>
                    <v-col
                      v-for="(item, idx) in documentTypes"
                      :key="`file-input-${idx}`"
                      cols="6"
                    >
                      <v-file-input
                        v-model="personalInfoModel.documents[idx]"
                        :placeholder="`Upload ${item}`"
                        :label="item"
                        multiple
                        prepend-icon="mdi-paperclip"
                        accept="image/*,.pdf"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-alert v-if="!!alertMessage" :type="alertType">{{
        alertMessage
      }}</v-alert>
      <v-card class="justify-center mx-4 my-4" color="white lighten-1">
        <v-btn
          class="ml-2"
          :disabled="selectedStep === 1"
          color="secondary"
          @click="selectedStep = selectedStep - 1"
          >Go Back</v-btn
        >
        <v-btn
          class="ml-2"
          :disabled="selectedStep === stepHeaders.length"
          color="primary"
          @click="continueStep"
          >Continue</v-btn
        >

        <v-btn class="ml-2" text @click="viewMode = true">Cancel</v-btn>
        <v-btn
          class="ml-2"
          v-if="selectedStep === stepHeaders.length"
          color="warning"
          :loading="loading"
          @click="submit"
          >Submit</v-btn
        >
      </v-card>
    </div>
  </div>
</template>

<script>
import graphqlMixin from "@/mixins/graphql-mixin.js";
import CustomerView from "@/components/CustomerView";
import { personalInfoData } from "@/helpers/formatPersonInfo";

const stepHeaders = [
  "Personal",
  "Contact",
  "Employment",
  "Finance",
  "Banking",
  "Documents",
];

export default {
  components: { CustomerView },
  mixins: [graphqlMixin],
  data: () => ({
    loading: false,
    deleteDocumentLoadingKey: null,
    tempDocuments: [],
    viewMode: true,
    tab: null,
    items: [
      "Personal",
      "Contact",
      "Employment",
      "Finance",
      "Banking",
      "Documents",
    ],
    stepHeaders,
    personalInfoModel: personalInfoData,

    phoneRules: [
      (v) => !!v || "Mobile Number is required",
      (v) =>
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(v) ||
        "Number must be valid",
    ],
    maritalRules: [(v) => !!v || "Marital Status is required"],
    dateRules: [(v) => !!v || "Date is required"],
    genderRules: [(v) => !!v || "Gender is required"],
    addressRules: [
      (v) => !!v || "Address is required",
      (v) => v.length <= 40 || "Address must be less than 40 characters",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) =>
        (v && v.length <= 25) || "Amount Name must be less than 25 characters",
    ],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    selectedDocument: null,
    documentType: null,
    documentTypes: [
      "TRN",
      "Pay Slip",
      "Bank Statement",
      "Valid ID",
      "Proof of Address",
    ],
    genders: ["Male", "Female"],
    maritalStatuses: ["Married", "Divorced", "Single"],
    payment_cycles: ["fortnightly", "monthly"],
    banks: [
      "NCB",
      "FCIB CIBC",
      "Scotiabank",
      "JMMB",
      "JN Bank",
      "CitiBank",
      "First Global Bank",
      "Sagicor",
    ],
    accountTypes: ["Cheque", "Saving"],
    dependants: ["0", "1", "2", "3+"],
    relations: [
      "Aunt",
      "Brother",
      "Daughter",
      "Father",
      "Husband",
      "Mother",
      "Other",
      "Sister",
      "Son",
      "Uncle",
      "Wife",
    ],
    socialMedia: ["Facebook", "Google", "Instagram", "Pinterest", "Twitter"],
    isDateOfBirthMenu: false,
    isEmployFromDate: false,
    isEmployToDate: false,
    selectedStep: 1,
    formStepValidities: stepHeaders.map(() => true), //   [true, true, true, true, true, true,true],
  }),
  computed: {
    user() {
      const { username, attributes } = this.$store.state.account.user || {};
      const { email, email_verified, phone_number, phone_number_verified } =
        attributes || {};
      return {
        username,
        email,
        email_verified,
        phone_number,
        phone_number_verified,
      };
    },

    document() {
      const document = this.$store.state.profile.document;
      return document;
    },
    personalInfo() {
      return this.$store.state.profile.personalInfo;
    },

    tabbedPersonalInfo() {
      return this.$store.getters["profile/tabbedPersonalInfo"];
    },

    maxDateRequired() {
      return new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
    },
    minDateRequired() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  watch: {
    personalInfo: {
      handler: function (personalInfo) {
        this.setModel(personalInfo);
      },
      deep: true,
    },
    "salariedBank.deposit": function (val) {
      console.log("deposit", val);
    },
    document: {
      handler: ({ content, filename }) => {
        try {
          const element = document.createElement("a");
          element.setAttribute("href", content);
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } catch (err) {
          console.log("show document error", err);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.viewMode = !this.$route.query.edit;
    this.setModel(this.personalInfo);
  },
  methods: {
    chkDepositBankAccount(evt) {
      if (!evt && this.personalInfoModel.bank_accounts.length === 1) {
        this.personalInfoModel.bank_accounts.push({
          salary: false,
          deposit: true,
          bank_name: "",
          branch_name: "",
          account_no: "",
          type: null,
        });
      } else if (evt && this.personalInfoModel.bank_accounts.length > 1) {
        this.personalInfoModel.bank_accounts.splice(1);
      }
    },
    changeMode() {
      this.viewMode = !this.viewMode;
    },
    setModel(personalInfo) {
      this.personalInfoModel = personalInfo.given_name
        ? { ...personalInfo }
        : { ...personalInfoData };
      delete this.personalInfoModel.document_keys;
      this.personalInfoModel.documents = Array.from(
        Array(this.documentTypes.length),
        () => null
      );
      //////////////////////////////////////////////
      this.personalInfoModel.phone_number = this.user.phone_number;
      this.personalInfoModel.email = this.user.email;
    },
    async deleteDocument(key) {
      console.log("deleteDocument key", key);
      try {
        this.deleteDocumentLoadingKey = key;
        await this.$store.dispatch("profile/deleteDocument", key);
      } catch (err) {
        console.log("err", err);
        this.setGraphQlError(err);
      } finally {
        this.deleteDocumentLoadingKey = null;
      }
    },

    getDocument(key) {
      console.log("getDocument key", key);
      this.$store.dispatch("profile/getDocument", key);
    },

    async submit() {
      console.log("personalInfoModel:", this.personalInfoModel);
      this.$refs[`form${(stepHeaders.length - 1).toString()}`].validate();
      if (!this.formStepValidities[stepHeaders.length - 1]) return;

      if (
        !stepHeaders.every((_, i) => {
          this.$refs[`form${(i + 1).toString()}`].validate();
          return this.formStepValidities[i + 1];
        })
      )
        return;

      let {
        given_name,
        birthdate,
        family_name,
        address,
        gender,
        home_number,
        maritalStatus,
        next_kin,
        social_media,
        employment,
        bank_accounts,
        documents: inputDocsArrays,
        number_of_dependents,
        outstanding_loan,
      } = { ...this.personalInfoModel };

      const phone_number = this.user.phone_number;
      const email = this.user.email;

      this.loading = true;
      try {
        number_of_dependents = parseInt(number_of_dependents, 10) || 0;

        /*  if (bank_accounts[0].deposit) {
          bank_accounts.splice(1, 1);
        }

        if (!bank_accounts[0].account_no) {
          bank_accounts.splice(0, 1);
        } */

        address = JSON.stringify(address);
        bank_accounts = bank_accounts
          .filter((x, i) => x.account_no && (i == 0 ? true : !x.deposit))
          .map((x) => JSON.stringify(x));
        employment = JSON.stringify(employment);

        next_kin = JSON.stringify(next_kin);
        social_media = social_media.map((x) => JSON.stringify(x));
        outstanding_loan = JSON.stringify(outstanding_loan);

        const outputDocList = [];
        try {
          console.log("submit--inputDocsArrays--first--", inputDocsArrays);

          for (let i = 0; i < inputDocsArrays.length; i++) {
            const files = inputDocsArrays[i] || [];
            console.log(`doc${i}`, files);

            for (let file of files) {
              //const file = files[0];
              const { lastModified, lastModifiedDate, name, size, type } = file;
              const base64 = await this.createBase64File(file);
              console.log("file base64", !!base64);
              const documentType = this.documentTypes[i];
              outputDocList.push(
                JSON.stringify({
                  lastModified,
                  lastModifiedDate,
                  name,
                  size,
                  type,
                  documentType,
                  base64,
                })
              );
            }
          }
        } catch (err) {
          console.log("submit err", err);
        }

        const personalInfo = {
          given_name,
          birthdate,
          family_name,
          address,
          gender,
          phone_number,
          employment,
          email,
          documents: outputDocList,
          home_number,
          maritalStatus,
          next_kin,
          social_media,
          number_of_dependents,
          outstanding_loan,
          bank_accounts,
        };

        console.log("submit--documents", outputDocList);
        console.log("submit--personalInfo", personalInfo);

        await this.$store.dispatch("profile/modifyPersonalInfo", personalInfo);

        console.log("success");
        this.viewMode = true;
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },

    continueStep() {
      this.$refs[`form${this.selectedStep.toString()}`].validate();
      if (this.formStepValidities[this.selectedStep]) this.selectedStep++;
    },

    async handleFile(e) {
      const base64 = await this.createBase64File(e.target.files[0]);
      console.log("handle-base64", base64);
    },

    createBase64File(file) {
      return new Promise((resolve) => {
        // prepare HTML5 FileReader
        const oReader = new FileReader();
        //setLoadingState(true);
        oReader.onload = (e) => {
          resolve(e.target.result);
        };
        // read selected file as DataURL
        oReader.readAsDataURL(file);
      });
    },
  },
};
</script>
